<script>
	let term = "";
	let book = null;

	let controller = new AbortController();
	let signal = controller.signal;

	const search = (e) => {

		controller.abort();

		controller = new AbortController();
		signal = controller.signal;

		const searchterm = e.target.value;
		if (searchterm.length > 2) {

			fetch("https://www.googleapis.com/books/v1/volumes?q=" + encodeURIComponent(searchterm), {
				signal: signal
			}).then(r => r.json()).then(json => {

				if (json.totalItems > 0) {
					book = json.items[0].volumeInfo;
				} else {
					book = null;
				}

				try {
					if (book.industryIdentifiers[0].identifier) {
						fetch("https://srg-cors-proxy.herokuapp.com/http://libgen.rs/json.php?fields=Title,md5&isbn=" + book.industryIdentifiers[0].identifier, {
							signal: signal
						}).then(r => r.json()).then(json => {
							if (json.length > 0) {
								book.downloadInformation = json;
							}
						})
					}
				} catch (e) { }

			});

		}

	}

	const engines = [
		{
			name: "Library Genesis",
			categories: ["Textbooks"],
			template: "http://libgen.rs/search.php?req=[[TERM]]",
			icon: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Libgen_logo.svg"
		},

		{
			name: "Z-Library",
			categories: ["Fiction", "Non-Fiction"],
			template: "https://b-ok.global/s/[[TERM]]",
			icon: "zlibrary.png"
		},

		{
			name: "Google Books",
			categories: ["No downloads"],
			template: "https://books.google.com/books?q=[[TERM]]",
			icon: null
		},

		{
			name: "Amazon US",
			categories: ["No downloads", "Paid"],
			template: "https://www.amazon.com/s?k=[[TERM]]",
			icon: null
		},

		{
			name: "Amazon UK",
			categories: ["No downloads", "Paid"],
			template: "https://www.amazon.co.uk/s?k=[[TERM]]",
			icon: null
		},

		{
			name: "Amazon CA",
			categories: ["No downloads", "Paid"],
			template: "https://www.amazon.ca/s?k=[[TERM]]",
			icon: null
		},

		{
			name: "Amazon AU",
			categories: ["No downloads", "Paid"],
			template: "https://www.amazon.com.au/s?k=[[TERM]]",
			icon: null
		},
	];
</script>

<main>

	<h1>Book search {"📚"}</h1>

	<input on:input={search} placeholder="search term" class="searchbox" bind:value={term} />

	{#if book}

		<h2>Is this your book?</h2>
		<div class="book">

			<div class="info">

				<h3 style="margin-top:0">
					{book.title} {#if book.subtitle}<i class="subtitle">- {book.subtitle}</i>{/if}
				</h3>

				{#if book.authors}
					<p>
						By {book.authors.join(", ")}
					</p>
				{/if}

				{#if book.description}
					<p class="description">
						{book.description.slice(0, 200)}...
					</p>
				{/if}

				<div class="actions">
					<a href={book.infoLink} target="_blank" rel="noreferrer">View on Google Books</a><br/>
					<span class="alink" on:click={() => {term = book.industryIdentifiers[0].identifier}}>Search by ISBN</span><br/>

					{#if book.downloadInformation}

						<a
							target="_blank"
							rel="noreferrer"
							href={"http://library.lol/main/" + book.downloadInformation[0].md5}
						>Download this book from Library Genesis</a>

					{:else}

						<span>Loading downloads...</span>

					{/if}


				</div>

			</div>

			<img src={book.imageLinks.thumbnail} alt={"Thumbnail of the book" + book.title} />

		</div>

	{/if}

	<h2>Search this book on:</h2>

	<div class="links">

		{#each engines as engine}

			<div class="link">

				<a href={term.length > 0 ? engine.template.replace("[[TERM]]", encodeURIComponent(term)) : null} target="_blank" rel="noreferrer">
					Search {engine.name}
				</a>

				<span class="categories">
					{#each engine.categories as category}
						<span class="category">{category}</span>
					{/each}
				</span>

			</div>

		{/each}

	</div>

</main>

<style>
	
	main {
		width: 500px;
	}

	.isbn {
		user-select: all;
	}

	.book {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;

		padding: 15px;
		border-radius: 5px;
		background-color: #FAFAFA;
		margin-bottom: 20px;
		border: 2px solid #EEE;
	}

	.book .info {
		padding-right: 10px;
	}

	.book .description {
		font-size: 0.7rem;
	}

	/* .book img {
		width: 40%;
	} */

	input {
		font-size: 2rem;
		font-weight: lighter;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		width: 100%;
	}

	span.category {
		font-size: 0.8rem;
		text-transform: uppercase;
		display: inline-block;
		margin: 3px 3px;
		padding: 2px;
		border-radius: 3px;
		background-color: #eee;
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}

	.link img {
		height: 1rem;
	}

	.link {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

</style>